import React from "react";
import classNames from "classnames";
import "./Skill.scss";

interface SkillProps {
  className?: string
  icon?: string
  skill: string
}

export const Skill: React.FC<SkillProps> = ({className, icon, skill}: SkillProps) => {
  return (
    <div className={classNames("Skill", className)}>
      <img src={icon} alt={skill} width="48px" height="48px"/>
      <span>{skill}</span>
    </div>
  )
}
