import React, {useEffect} from 'react';
import './App.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faChalkboardTeacher,
  faCloud, faDatabase,
  faDollarSign,
  faLaptopCode,
  faLayerGroup,
  faSitemap,
} from "@fortawesome/free-solid-svg-icons"
import {Footer} from './components/Footer/Footer';
import {Developer} from "./components/Developer/Developer";
import {Skill} from "./components/Skill/Skill";
import Frontend from "./icons/html5/html5-original.svg";
import Backend from "./icons/bash/bash-original.svg";
import Java from "./icons/java/java-original.svg";
import Kotlin from "./icons/kotlin/kotlin-original.svg";
import SpringBoot from "./icons/spring/spring-original.svg";
import TypeScript from "./icons/typescript/typescript-original.svg";
import ReactIcon from "./icons/react/react-original.svg";
import ReduxIcon from "./icons/redux/redux-original.svg";
import SassIcon from "./icons/sass/sass-original.svg";
import TailwindIcon from "./icons/tailwindcss/tailwindcss-plain.svg";
import Angular from "./icons/angularjs/angularjs-original.svg";
import MongoDb from "./icons/mongodb/mongodb-original.svg";
import Neo4J from "./icons/neo4j/neo4j-original.svg";
import KafkaIcon from "./icons/apachekafka/apachekafka-original.svg";
import NatsIcon from "./icons/nats/NATS-IO.svg";
import GitLabIcon from "./icons/gitlab/gitlab-original.svg";
import PostgreSql from "./icons/postgresql/postgresql-original.svg";
import AmazonWebServices from "./icons/amazonwebservices/amazonwebservices-original.svg";
import ArgoIcon from "./icons/argocd/argocd-original.svg";
import QuarkusIcon from "./icons/quarkus/quarkus-original.svg";
import GoIcon from "./icons/go/go-original.svg";
import CircleCi from "./icons/circleci/circleci-plain.svg";
import Jenkins from "./icons/jenkins/jenkins-original.svg";
import Docker from "./icons/docker/docker-original.svg";
import Kubernetes from "./icons/kubernetes/kubernetes-plain.svg";
import {Card} from "./components/Card/Card";
import {Trainer} from "./components/Trainer/Trainer";
import {SocialLinks} from "./components/SocialLinks/SocialLinks";
import ReactDOM from 'react-dom';

function App() {

  const showMore = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    const listener = () => {
      const y = window.scrollY;
      if (y > 100) {
        const more = window.document.getElementById("more")
        if (!!more?.style) {
          more.style.opacity = "0";
        }
      } else {
        const more = window.document.getElementById("more")
        if (!!more?.style) {
          more.style.opacity = "1";
        }
      }
    }
    window.addEventListener('scroll', listener)
    return () => window.removeEventListener('scroll', listener)
  }, [])

  useEffect(() => {
    const contact = window.document.getElementById("contact")
    ReactDOM.render(
      <>
        <a href='mailto:projekte@stefan-hauschildt.de' style={{textDecoration: 'none', color: 'unset'}}>
          <div className="button outline">Anfrage stellen</div>
        </a>
        <SocialLinks/>
      </>
      , contact
    )
  }, [])

  return (
    <div className="App">
      <div className="d-flex flex-center" id="more">
        <div className="read__more" onClick={showMore}>
          <div className="flex-1 more">weiter</div>
          <FontAwesomeIcon icon={faArrowDown} className="arrow" size="lg"/>
        </div>
      </div>

      <div className="bg-primary pb-2">
        <Developer/>

        <div className="container">
          <div className="cols-2">
            <Card icon={<img src={Frontend} alt="Frontend"/>} title="Frontend" subtitle="User Experience im Fokus">
              <div className="flex-1 mb-3">
                <p>
                  Der häufigste Berührungspunkt der Kunden mit einer Anwendung ist das Frontend. Gemeinsam mit den
                  Anwendern möchte ich die Use Cases ausarbeiten und in verständliche Oberflächen gießen. <strong>Agile
                  Prozesse</strong> und kleine Schritte mit häufigem Feedback sind dabei mein Schlüssel zum Erfolg.
                </p>
                <p>
                  Moderne Frontend-Technologien wie React oder Angular begleiten mich seit einigen Jahren und ich freue
                  mich mein Wissen mit Euren Teams zu teilen.
                </p>
              </div>
              <div className="d-flex flex-wrap justify-content-center gap-2">
                <Skill icon={TypeScript} skill="TypeScript"/>
                <Skill icon={ReactIcon} skill="React"/>
                <Skill icon={Angular} skill="Angular"/>
                <Skill icon={ReduxIcon} skill="Redux"/>
                <Skill icon={SassIcon} skill="SASS"/>
                <Skill icon={TailwindIcon} skill="tailwindcss"/>
              </div>
            </Card>
            <Card icon={<img src={Backend} alt="Backend"/>} title="Backend" subtitle="Where the magic happens">
              <div className="flex-1 mb-3">
                <p>
                  Die Businesslogik teile ich gerne nach Fachlichkeiten auf. <strong>Domain Driven Design</strong> ist
                  hier das Schlüsselwort, um eine Idee für die Teilung zu bekommen. Alles weitere entscheidet dann die
                  Realität.
                </p>
                <p>
                  Bei der Wahl der passenden Technologie spielen neben den Erfahrungen im Team auch immer häufiger
                  andere Faktoren eine Rolle. Muss ein Service zu Spitzenzeiten stark horizontal skalieren, zählen
                  RAM, CPU und Startzeit stärker und anstatt Spring Boot fällt die Wahl auf Qarkus, Javalin oder
                  Micronaut.
                </p>
              </div>
              <div className="d-flex flex-wrap justify-content-center gap-2">
                <Skill icon={Java} skill="Java"/>
                <Skill icon={Kotlin} skill="Kotlin"/>
                <Skill icon={SpringBoot} skill="Spring Boot"/>
                <Skill icon={QuarkusIcon} skill="Quarkus"/>
                <Skill icon={GoIcon} skill="go"/>
                <Skill icon={KafkaIcon} skill="Kafka"/>
                <Skill icon={NatsIcon} skill="NATS"/>
              </div>
            </Card>
          </div>
          <div className="cols-2">
            <Card icon={<FontAwesomeIcon icon={faLayerGroup} width={100} height={100}/>} title="CI / CD"
                  subtitle="You build it, you run it">
              <div className="flex-1 mb-3">
                <p>
                  Selbst auf den Knopf drücken zu können, damit die geschriebene Software auf Production deployed wird,
                  ist eine unglaubliche Freiheit und erleichtert die tägliche Arbeit als Entwickler ungemein. Noch
                  schöner wird es, wenn dieses Drücken gar nicht mehr notwendig ist, weil man mit automatischen Tests
                  und statischer Codeanalyse ein so großes Vertrauen schafft, dass Änderungen vollautomatisch live gehen
                  können.
                </p>
              </div>
              <div className="d-flex flex-wrap justify-content-center gap-2">
                <Skill icon={GitLabIcon} skill="GitLab CI"/>
                <Skill icon={CircleCi} skill="Circle CI"/>
                <Skill icon={Jenkins} skill="Jenkins"/>
                <Skill icon={Docker} skill="Docker"/>
                <Skill icon={AmazonWebServices} skill="AWS"/>
                <Skill icon={Kubernetes} skill="Kubernetes"/>
                <Skill icon={ArgoIcon} skill="ArgoCD"/>
              </div>
            </Card>
            <Card icon={<FontAwesomeIcon icon={faDatabase} width={100} height={100}/>} title="Datenbanken"
                  subtitle="Viele Daten richtig speichern">
              <div className="flex-1 mb-3">
                <p>
                  SQL und NoSQL gehen in modernen Projekten oft Hand in Hand.
                </p>
                <p>
                  Sind alle Daten gleich? Also haben sie die gleichen Ausprägungen? Werden Daten häufiger geschrieben
                  oder gelesen? Wie häufig? Oder stehen vielleicht die Beziehungen zwischen Daten im Vordergrund und die
                  Wahl sollte auch Neo4J fallen?
                </p>
                <p>
                  Gerne unterstütze ich meine Kunden bei der Suche nach der passenden Lösung.
                </p>
              </div>
              <div className="d-flex flex-wrap justify-content-center gap-2">
                <Skill icon={MongoDb} skill="MongoDB"/>
                <Skill icon={PostgreSql} skill="PostgreSQL"/>
                <Skill icon={Neo4J} skill="Neo4J"/>
              </div>
            </Card>
          </div>
        </div>

      </div>

      <div className="bg-white flex-center py-2 pb-12">
        <div className="container">
          <div className="cols-2 cols-lg-3">
            <Card flat icon={<FontAwesomeIcon icon={faCloud}/>} title="Moderne Software"
                  subtitle="fullstack and cloud native">
              <p>
                Ich möchte gemeinsam mit meinen Kunden eine moderne und für die Geschäftsprozesse passende Software
                entwickeln.
              </p>
            </Card>
            <Card flat icon={<FontAwesomeIcon icon={faDollarSign}/>} title="Mehrwerte" subtitle="Kundennutzen im Fokus">
              <p>
                Am Ende des Tages ist wichtig, dass meinem Kunden ein Mehrwert entsteht. Und dies am besten möglichst
                schnell und unkompliziert.
              </p>
            </Card>

            <Card flat icon={<FontAwesomeIcon icon={faChalkboardTeacher}/>} title="Wissensverteilung"
                  subtitle="keine Inseln">
              <p>
                Wenn das Projekt einmal in die Hände der internen Kolleg:innen übergeben wird, dürfen keine Lücken
                entstehen. Um das zu erreichen ist mir ein enger Austausch und eine gute Kommunikation sehr wichtig.
              </p>
            </Card>
          </div>
        </div>
      </div>

      <div className="bg-primary pb-2">
        <Trainer/>

        <div className="container">
          <div className="cols-2">
            <Card icon={<FontAwesomeIcon icon={faLaptopCode}/>} title="Entwicklung"
                  subtitle="Was ist eigentlich TypeScript?">
              <p>
                Bei der Einführung einer neuen Technologie erwarten einen viele spannende Herausforderungen. Als
                externer Trainer helfe ich Eurem Team, diesen Schritt gemeinsam zu gehen.
              </p>
              <p>
                Gerne gehen wir dabei auf konkrete Probleme ein und arbeiten an echtem Code.
              </p>
            </Card>

            <Card icon={<FontAwesomeIcon icon={faSitemap}/>} title="Architektur" subtitle="Domain Driven Design">
              <p>
                Wie zerlege ich meine alte Software Stück für Stück in moderne Microservices? Und wie mache ich dabei
                nichts kaputt?
              </p>
              <p>
                Oft gibt es bereits Software, die über die Jahre gewachsen ist. Diese in einem Stück zu ersetzen ist
                meist eine zu große Aufgabe. Vielversprechender ist es einzelne Fachlichkeiten herauszuziehen, um so den
                alten Monolithen Stück für Stück zu zerlegen.
              </p>
            </Card>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
}

export default App;
