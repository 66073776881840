import React from "react"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import { faGithub, faInstagram, faLinkedin, faMediumM, faXing } from '@fortawesome/free-brands-svg-icons';
import {SocialLinkButton} from "./SocialLinkButton"
import "./SocialLinks.scss"

export interface SocialLink {
  name: string
  href: string
  icon: IconProp
}

const social: SocialLink[] = [
  {
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/in/stefan-hauschildt-hamburg',
    icon: faLinkedin,
  },
  {
    name: 'Xing',
    href: 'https://www.xing.com/profile/Stefan_Hauschildt5',
    icon: faXing,
  },
  {
    name: 'GitHub',
    href: 'https://www.github.com/shauschi/',
    icon: faGithub,
  },
  {
    name: 'Medium',
    href: 'https://medium.com/@stefan.h',
    icon: faMediumM,
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/_shauschi/',
    icon: faInstagram,
  },
];

export const SocialLinks: React.FC = () => {

  return (
    <div className="social__links">
      {social.map((it) => <SocialLinkButton key={it.name} socialLink={it} />)}
    </div>
  )
}
