import React, {PropsWithChildren, useEffect, useRef, useState} from "react";
import classNames from "classnames"
import "./HeroImage.scss"


interface HeroImageProps {
  className?: string
  src: string
  srcSet?: string
  alt: string
}

export const HeroImage: React.FC<HeroImageProps> = ({
                                                      className,
                                                      src,
                                                      srcSet,
                                                      alt,
                                                      children
                                                    }: PropsWithChildren<HeroImageProps>) => {
  const containerRef = useRef<HTMLDivElement | null>()
  const imgRef = useRef<HTMLImageElement | null>()
  const [showImage, setShowImage] = useState(false)

  const parallaxScroll = () => requestAnimationFrame(() => {
    const top = containerRef.current?.getBoundingClientRect().top || 0
    if (top < window.innerHeight + 500) {
      setShowImage(true)

      if (imgRef.current) {
        imgRef.current.style.transform = `translateY(${0.3 * top}px)`
      }
    }
  })

  useEffect(() => {
    document.addEventListener("scroll", parallaxScroll)
    return () => document.removeEventListener("scroll", parallaxScroll)
  }, [])

  return (
    <div className={classNames("HeroImage", className)} ref={ref => containerRef.current = ref}>
      {showImage &&
        <img
            ref={ref => imgRef.current = ref}
            srcSet={srcSet}
            sizes="
              (min-width: 2400px) 3600px,
              (min-width: 1800px) 2400px,
              (min-width: 1200px) 1800px,
              (min-width: 800px) 1200px,
              (min-width: 600px) 800px,
              (min-width: 400px) 600px,
              400px
            "
            src={src}
            alt={alt}
            className="HeroImage__img"
        />
      }
      {children}
    </div>
  )
}
