import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Section} from "../Section/Section";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";

export const Developer: React.FC = () => {

  return (
    <Section
      className="rel-8"
      title="Softwareentwickler"
    >
      <p>
        Mein Name ist Stefan Hauschildt und ich bin freiberuflicher Softwareentwickler und Trainer. Als externe
        Unterstützung möchte ich die Projekte meiner Kunden zum Erfolg führen. Ich arbeite seit 2013 in Vollzeit als
        freiberuflicher Entwickler und schätze die immer neuen Herausforsderungen. Neue Technologien und fachliche
        Feinheiten warten in jedem neuen Projekt auf mich und dieser Teil der Arbeit gefällt mir sehr.
      </p>
      <blockquote className="quote">Ein persönlicher Austausch ist der Beginn einer guten Zusammenarbeit.</blockquote>
      <p>
        Ich wohne in Niedersachsen, an der südlichen Grenze zu Hamburg und freue mich, dass ich so schnell bei
        meinen Hamburger Kunden vor Ort sein kann. Gemeinsam nach Scrum oder Kanban freut es mich immer, wenn ein neues
        Stück Software beim Kunden live geht. Am liebsten natürlich vollautomatisch und regelmäßig.
      </p>
      <p>
        Als Fullstack-Entwickler fühle mich im Frontend und Backend gleichermaßen wohl. Gerne suche ich mit dem Team
        nach der passenden Technologie, um ein Problem zu lösen. Ich freue mich auf Eure Anfragen.
      </p>
      <div className="d-flex justify-content-center mt-3">
        <a href='mailto:projekte@stefan-hauschildt.de' style={{textDecoration: 'none', color: 'unset'}}>
          <div className="button"><FontAwesomeIcon icon={faEnvelope} className="mr-2"/>Projektanfrage stellen</div>
        </a>
      </div>
    </Section>
  )
}
