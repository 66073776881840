import React, {PropsWithChildren} from "react";
import "./Card.scss";
import classNames from "classnames";

interface CardProps {
  icon?: React.ReactNode
  title?: string
  subtitle?: string
  flat?: boolean
}

export const Card: React.FC<CardProps> = ({icon, title, subtitle, flat, children}: PropsWithChildren<CardProps>) => {

  return (
    <div className={classNames("Card", {Card__flat: flat})}>
      <div className="Card__body flex-column">
        <div className="Card__icon">
          {icon}
        </div>
        <div className="Card__title">{title}</div>
        <div className="Card__subtitle">{subtitle}</div>
        <div className="flex-1 flex-column my-2">
          {children}
        </div>
      </div>
    </div>
  )
}
