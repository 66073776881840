import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Section} from "../Section/Section";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";

export const Trainer: React.FC = () => {

  return (
    <Section
      className="rel-8"
      title="Trainer"
    >
      <p>
        Als freiberuflicher Entwickler bringe ich immer neues Wissen mit in meine Projekte, neue Perspektiven und
        Herangehensweisen. Natürlich lerne ich auch selbst in jedem Projekt dazu. Dieser Austausch und die Verteilung
        von Wissen macht mit so viel Spaß, dass ich mich 2020 dazu entschieden habe auch als Trainer tätig zu werden.
      </p>
      <p>
        Ob Euer Team bei der Einführung einer neue Technologie Unterstützung gebrauchen kann oder ihr im Rahmen eines
        Ausbildungsprogramms eher theoretische Inhalte vermitteln wollt, spielt dabei keine Rolle.
      </p>
      <div className="d-flex justify-content-center">
        <blockquote className="quote">Aus der Praxis lernen</blockquote>
      </div>
      <p>
        Wichtig ist, dass das Lernen Spaß macht und der Bezug zur Praxis im Vordergrund steht. Schließlich gibt es ganz
        konkrete Probleme zu lösen. Ich freue mich gemeinsam mit Euch ein passendes Training zu planen und umzusetzen.
      </p>
      <div className="d-flex justify-content-center mt-3">
        <a href='mailto:projekte@stefan-hauschildt.de' style={{textDecoration: 'none', color: 'unset'}}>
          <div className="button"><FontAwesomeIcon icon={faEnvelope} className="mr-2"/>Stefan als Trainer anfragen</div>
        </a>
      </div>
    </Section>
  )
}
