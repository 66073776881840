import React from "react"
import { SocialLink } from "./SocialLinks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./SocialLinks.scss"

interface SocialLinkButtonProps {
  socialLink: SocialLink
}

export const SocialLinkButton: React.FC<SocialLinkButtonProps> = ({ socialLink }) => {

  return (
    <div className="social__link__button">
      <a target='_blank' rel='noreferrer' href={socialLink.href} title={socialLink.name}>
        <FontAwesomeIcon icon={socialLink.icon} size="2x" />
      </a>
    </div>
  )
}
