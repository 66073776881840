import React, {PropsWithChildren} from "react";
import classNames from "classnames";
import "./Section.scss"

interface SectionProps {
  className?: string;
  title: string;
  additionalContent?: React.ReactNode
}

export const Section: React.FC<SectionProps> = ({
                                                  className,
                                                  title,
                                                  additionalContent,
                                                  children
                                                }: PropsWithChildren<SectionProps>) => {

  return (
    <section className={classNames("Section", className)}>
      <div className="container">
        <div className="Section__Main">
          <h2 className="Section__title">{title}</h2>
          <div className="Section__body">
            {children}
          </div>
        </div>
      </div>
    </section>
  )
}
