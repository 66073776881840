import {HeroImage} from "../HeroImage/HeroImage";
import "./Footer.scss"
import React from "react";

export const Footer: React.FC = () => {
  return (
    <footer className="Footer">
      <HeroImage
        srcSet="
          stefan_hauschildt_landungsbruecken_3600x.jpg 3600w,
          stefan_hauschildt_landungsbruecken_2400x.jpg 2400w,
          stefan_hauschildt_landungsbruecken_1800x.jpg 1800w,
          stefan_hauschildt_landungsbruecken_1200x.jpg 1200w,
          stefan_hauschildt_landungsbruecken_800x.jpg 800w,
          stefan_hauschildt_landungsbruecken_600x.jpg 600w,
          stefan_hauschildt_landungsbruecken_400x.jpg 400w,
        "
        src="/stefan_hauschildt_landungsbruecken_1800x.jpg"
        alt="Stefan Hauschildt an den Landungsbrücken"
      >
        <div className="Footer__container">
          <div className="container d-flex align-items-end my-3">
            <div className="d-flex w-100 overview">
              <div className="Footer__name">Stefan Hauschildt</div>
              <div className="Footer__impressum">
                <a href="/impressum.html" target="_blank" style={{textDecoration: 'none', color: 'unset'}}>
                  Impressum
                </a>
              </div>
              <div className="Footer__date">Januar 2024</div>
            </div>
          </div>
        </div>
      </HeroImage>
    </footer>
  )
}
